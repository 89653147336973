import classes from '../Header/Header.module.sass'

const TopMenu = () => {
    return (
        <ul id='topmenu' className={classes.topMenu}>
            <li>
                <a href="#demo">Демо</a>
            </li>
            <li>
                <a href="#win">1win</a>
            </li>
            <li>
                <a href="#bonus">Бонусы</a>
            </li>
            <li>
                <a href="#reg">Регистрация</a>
            </li>
            <li>
                <a href="#shemas">Схемы</a>
            </li>
            <li>
                <a href="#signal">Сигналы</a>
            </li>
            <li className="parent">
                <a href="#mobile">Мобильная версия</a>
                <ul className='childMenu'>
                    <li>
                        <a href="#mobile">Мобильная версия</a>
                    </li>
                    <li>
                        <a href="#mobile">Мобильная версия</a>
                    </li>
                </ul>
            </li>
            <li>
                <a href="#money">Вывод</a>
            </li>
        </ul>
    );
};

export default TopMenu;