import React from 'react';
import clasnames from './Begin.module.sass';
import Button from "../Button/Button";

const Begin = () => {
    return (
        <section className={clasnames.begin}>
            <div className={clasnames.container}>
            <h2 className={clasnames.header}>Как начать играть в Лаки Джет?</h2>
            <div className={clasnames.steps}>
                <div className={clasnames.step}>
                    <h4 className={clasnames.number}>01</h4>
                    <h4 className={clasnames.title}>Зарегистрируйся на сайте по ссылке</h4>
                    <p className={clasnames.desc}>Просто перейди по ссылке ниже и попадешь на форму регистрации
                        официального сайта 1win.</p>
                </div>
                <div className={clasnames.step}>
                    <h4 className={clasnames.number}>02</h4>
                    <h4 className={clasnames.title}>Активируй промкод при регистрации</h4>
                    <p className={clasnames.desc}>При регистрации укажи промокод «kwp1» и получи 500% бонуса на первый
                        депозит.</p>
                </div>
                <div className={clasnames.step}>
                    <h4 className={clasnames.number}>03</h4>
                    <h4 className={clasnames.title}>Залетай в игру и выигрывай</h4>
                    <p className={clasnames.desc}>Окунись в мир адреналина и азарта с возможностью заработать большую сумму денег.</p>
                </div>
            </div>
            {/*<div className={clasnames.button}></div>*/}
                <Button
                    text={"ЗАРЕГИСТРИРОВАТЬСЯ"}
                    angle={'-2.6'}
                />
            </div>
        </section>
    );
};

export default Begin;