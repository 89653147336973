import React from 'react';
import classes from './Answer.module.sass';

const Answer = () => {
    return (
        <section className={classes.answer}>
            <div className={classes.container}>
                <h3 className={classes.title}>Ответы на популярные вопросы</h3>
                <div className={classes.list}>
                    <div className={classes.item}>
                        <div className='answerHead'>
                            <span>С какими деньгами можно начинать играть?</span>
                        </div>
                        <div className='answerText'>
                            <p>Здесь все зависит от ваших финансовых возможностей. Минимальная сумма, которая может быть
                                внесена на игровой счет, составляет всего 500 рублей. Можете попробовать начать с
                                минималки.</p>
                        </div>
                    </div>
                    <div className={classes.item}>
                        <div className='answerHead'>
                            <span>Является ли Lucky jet аналогом Aviator?</span>
                        </div>
                        <div className='answerText'>
                            <p>Геймплей, если не идентичен, то крайне схож. Если вы в поисках похожей игры на Авиатор с
                                самолетом, то Лаки Джет будет вам интересна. В обоих проектах концепция заключается в
                                росте коэффициента без возможности определить, когда он прекратится.</p>
                        </div>
                    </div>
                    <div className={classes.item}>
                        <div className='answerHead active'>
                            <span>Как сыграть в демо-версию Лаки Джет?</span>
                        </div>
                        <div className='answerText active'>
                            <p>
                                В краш-игре принимаются ставки только на реальную валюту (в том числе рубли). Запуск
                                игры
                                без депозита и регистрации возможен, однако, при нажатии по кнопке «СТАВКА» всплывет
                                окно с
                                необходимостью зарегистрироваться или пополнить счет.
                            </p>
                        </div>
                    </div>
                    <div className={classes.item}>
                        <div className='answerHead'>
                            <span>Как попасть на официальный сайт Lucky Jet?</span>
                        </div>
                        <div className='answerText'>
                            <p>
                                Игра выступает эксклюзивным азартным проектом казино 1WIN. Запуская Лаки Джет в 1Вин, вы
                                можете быть уверены в оригинальности краш-игры и гарантиях ее честности.
                            </p>
                        </div>
                    </div>
                    <div className={classes.item}>
                        <div className='answerHead'>
                            <span>Какой максимальный множитель?</span>
                        </div>
                        <div className='answerText'>
                            <p>
                                В Lucky Jet можно поймать коэффициент x20000 и даже выше. Безусловно, такого уровня
                                множители встречаются нечасто. Значительно чаще можно выводить выигрыши по коэффициенту
                                x5-10.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Answer;