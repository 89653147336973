import React from 'react';
import classes from './Adv.module.sass';

const Adv = () => {
    return (
        <section className={classes.adv}>
            <div className={classes.container}>
                <h3 className={classes.title}>Плюсы и минусы Lucky Jet</h3>
                <div className={classes.desc}>
                    Так как игра обрела успех сразу же после релиза, нет сомнений в качественном подходе к ее разработке.
                    Если механика была позаимствована у всем известного Авиатора, то с точки зрения графики, персонажа Лаки
                    Джет уникальный азартный проект.
                </div>
                <div className={classes.table}>
                    <div className={classes.top}>ПЛЮСЫ</div>
                    <div className={classes.top}>МИНУСЫ</div>
                    <div className={classes.ceil}>Честная генерация результатов</div>
                    <div className={classes.ceil}>Возможно окончание раунда на x1.00</div>
                    <div className={classes.ceil}>Регулярные высокие множители</div>
                    <div className={classes.ceil}>Непредсказуемое завершение раунда</div>
                    <div className={classes.ceil}>Открытая статистика</div>
                    <div className={classes.ceil}></div>
                    <div className={classes.ceil}>Стильная графика</div>
                    <div className={classes.ceil}></div>
                    <div className={classes.ceil}>Две ставки</div>
                    <div className={classes.ceil}></div>
                    <div className={classes.ceil}>Раунд от 10 рублей</div>
                    <div className={classes.ceil}></div>
                    <div className={classes.ceil}>Автофункции</div>
                    <div className={classes.ceil}></div>
                </div>
                <div className={classes.desc}>
                    Однозначно краш-игра будет интересна тем, кто устал от стандартных видеослотов или никогда ими не интересовался, но любит азартные развлечения. Можно ли приумножить в несколько раз ставку в одном раунде? Без сомнений, о чем говорит открытая статистика, где множители x5-10 и выше встречаются часто.
                </div>
                <div className={classes.desc}>
                    Также в казино 1WIN можно найти другие азартные игры, такие как рулетка, блэкджек, покер и многие другие, включая краш-игру «Плинко», которая тоже имеет своих поклонников.
                </div>
            </div>
        </section>
    );
};

export default Adv;