import React from 'react';
import classes from './Play.module.sass';
import playImg from '../../assets/play.jpg';
import Button from "../Button/Button";

const Play = () => {
    return (
        <section className={classes.play}>
            <h1 className={classes.title}>Играть в Lucky Jet на официальном сайте 1WIN
            </h1>
            <div className={classes.image}>
                <img src={playImg} alt="play"/>
            </div>
            <div className={classes.desc}>
                <p>
                    Приглашаем в одну из лучших игр от казино 1WIN Lucky Jet, в которой на высокие коэффициенты есть
                    огромные шансы у каждого! Если вы любите испытывать по-настоящему азартные эмоции, без сомнений
                    останетесь довольны игрой Лаки Джет на деньги. Красивая графика, большие множители, приятный
                    саундтрек и быстрые раунды – то, что ценят любители популярной в 2024 году краш-игры.
                </p>
                <p>
                    Но и это еще не все – каждому, кто зарегистрировался на 1win предоставляется промокод, позволяющий
                    увеличить сумму первого депозита до 500%.
                </p>
            </div>
            <div className={classes.button}>
                <Button
                    text={"ИГРАТЬ В LUCKY JET"}
                    angle= {'0.68'}
                />
                {/*<button>ИГРАТЬ В LUCKY JET</button>*/}
            </div>
        </section>
    );
};

export default Play;