import React from 'react';
import classes from './Reviews.module.sass';
import stars from '../../assets/stars.svg';
import button from '../../assets/reviewsBtn.svg';
import Button from "../Button/Button";

const Reviews = () => {
    return (
        <section className={classes.reviews}>
            <div className={classes.container}>
                <h3 className={classes.title}>Отзывы Lucky Jet</h3>
                <div className={classes.list}>
                    <div className="reviews-item">
                        <div className="reviews-item-name">
                            Гриша
                        </div>
                        <div className="reviews-item-stars">
                            <img src={stars}/>
                        </div>
                        <div className="reviews-item-text">
                            Давно искал такого рода игру, очень доволен и понравилась. Спасибо администрации за игру,
                            теперь буду играть в неё весь день. Всем рекомендую Lucky Jet, гарантирую, что всем она
                            понравится и будете долго в неё играть.
                        </div>
                    </div>
                    <div className="reviews-item">
                        <div className="reviews-item-name">
                            Владимир
                        </div>
                        <div className="reviews-item-stars">
                            <img src={stars}/>
                        </div>
                        <div className="reviews-item-text">
                            Поиграл в эту игру. На самом деле затягивает, правда я и так любитель азартных игр. Потому
                            что сам очень азартный. Хорошая задумка игры у разработчиков.
                        </div>
                    </div>
                    <div className="reviews-item">
                        <div className="reviews-item-name">
                            Егор
                        </div>
                        <div className="reviews-item-stars">
                            <img src={stars}/>
                        </div>
                        <div className="reviews-item-text">
                            Хочу отметить, как же хорошо сделан сайт. Приятный интерфейс, из-за которого не хочется
                            уходить, максимально удобный и простой, что очень важно. Lucky Jet — это классная игра! Как
                            будто бы сон. Мало того, что тут очень легко выигрывать, так еще и бонусы, благодаря которым
                            выигрывать становится еще легче. Не было пока что ни одного дня, когда я уходил бы в минус
                            или даже в ноль, только профит.
                        </div>
                    </div>
                    <div className="reviews-item">
                        <div className="reviews-item-name">
                            Петр
                        </div>
                        <div className="reviews-item-stars">
                            <img src={stars}/>
                        </div>
                        <div className="reviews-item-text">
                            Лакиджет - это великолепное сочетание простоты и умелой стратегии. Сайт lucky jet интуитивно
                            понятен, а интерфейс пользователю позволяет легко планировать ставки и следить за игровыми
                            сессиями. С момента своего первого выигрыша я стал настоящим фанатом этой игры. Она не
                            только помогла скрасить свободное время, но и подарила множество ярких эмоций. Люблю играть
                            на деньги, ведь азарт и возможность выиграть делают игру ещё более привлекательной. Спасибо
                            команде 1win за такой продукт!
                        </div>
                    </div>
                    <div className="reviews-item">
                        <div className="reviews-item-name">
                            Влад
                        </div>
                        <div className="reviews-item-stars">
                            <img src={stars}/>
                        </div>
                        <div className="reviews-item-text">
                            Прикольная игруха, я поднял немного деньжат на ней, и это ещё не все, я ещё и классно провел
                            время, так как игра интересно задумана авторами)))
                        </div>
                    </div>
                    <div className="reviews-item hidden">
                        <div className="reviews-item-name">
                            Влад
                        </div>
                        <div className="reviews-item-stars">
                            <img src={stars}/>
                        </div>
                        <div className="reviews-item-text">
                            Прикольная игруха, я поднял немного деньжат на ней, и это ещё не все, я ещё и классно провел
                            время, так как игра интересно задумана авторами)))
                        </div>
                    </div>
                    <div className="reviews-item hidden">
                        <div className="reviews-item-name">
                            Влад
                        </div>
                        <div className="reviews-item-stars">
                            <img src={stars}/>
                        </div>
                        <div className="reviews-item-text">
                            Прикольная игруха, я поднял немного деньжат на ней, и это ещё не все, я ещё и классно провел
                            время, так как игра интересно задумана авторами)))
                        </div>
                    </div>
                    <div className="reviews-item hidden">
                        <div className="reviews-item-name">
                            Влад
                        </div>
                        <div className="reviews-item-stars">
                            <img src={stars}/>
                        </div>
                        <div className="reviews-item-text">
                            Прикольная игруха, я поднял немного деньжат на ней, и это ещё не все, я ещё и классно провел
                            время, так как игра интересно задумана авторами)))
                        </div>
                    </div>
                </div>
                <Button
                    text={"Смотреть еще..."}
                    angle={'-0.68'}
                    onClick = {(e) => {
                        e.target.classList.add('hidden');
                        document.querySelectorAll('.reviews-item').forEach(e => {
                            e.classList.remove('hidden');
                    });
                    }}
                />
            </div>
        </section>
    );
};

export default Reviews;