import LogoImg from '../../assets/logo.svg'
import classes from '../Header/Header.module.sass'

const Logo = () => {
    return (
        <div className={classes.logo}>
            <a href='/'>
                <img src={LogoImg} alt='Lucky Jet'/>
            </a>
        </div>
    );
};

export default Logo;