import React from 'react';
import classes from './Button.module.sass';

const Button = ({text, angle}) => {
    return (
        <button
            className={classes.button}
            style={{transform: `rotate(${angle}deg)`, }}
            onClick={(e) => {
            window.location.href = 'https://1wbhk.com/v3/lucky-jet-updated?p=80hd'
                }
            }>
            {text}
        </button>
    );
};

export default Button;