import React from 'react';
import classes from'./Footer.module.sass';
import uil from "../../assets/uil_copy.svg";
import Button from "../Button/Button";

const Footer = () => {
    return (
        <section className={classes.footer}>
            <div className={classes.container}>
                <div className={classes.wrapper}>
                    <div className={classes.left}>
                        <div className={classes.subtitle}>
                            Играй и получай
                        </div>
                        <div className={classes.title}>
                            500% БОНУС
                        </div>
                        <div className={classes.subtitle}>
                            При первой регистрации
                        </div>
                        <div className={classes.promocode}>
                            По промокоду: <span>LackJet500
                            <a onClick={
                                (e) => {
                                    navigator.clipboard.writeText('LackJet500')
                                    e.target.parentElement.classList.toggle('active')
                                }
                            } className='promocodeLink'>
                                <svg xmlns="http://www.w3.org/2000/svg"
                                     width="0.555556in" height="0.555556in"
                                     viewBox="0 0 50 50">
                                    <path id="copy"
                                          fill="#fff" stroke="black" strokeWidth="1"
                                          d="M 43.75,18.62
                               C 43.73,18.43 43.69,18.24 43.63,18.06
                                 43.63,18.06 43.63,17.88 43.63,17.88
                                 43.53,17.66 43.39,17.46 43.23,17.29
                                 43.23,17.29 30.73,4.79 30.73,4.79
                                 30.56,4.63 30.36,4.49 30.15,4.40
                                 30.08,4.39 30.02,4.39 29.96,4.40
                                 29.75,4.28 29.51,4.20 29.27,4.17
                                 29.27,4.17 20.83,4.17 20.83,4.17
                                 19.18,4.17 17.59,4.83 16.41,6.00
                                 15.24,7.17 14.58,8.76 14.58,10.42
                                 14.58,10.42 14.58,12.50 14.58,12.50
                                 14.58,12.50 12.50,12.50 12.50,12.50
                                 10.84,12.50 9.25,13.16 8.08,14.33
                                 6.91,15.50 6.25,17.09 6.25,18.75
                                 6.25,18.75 6.25,39.58 6.25,39.58
                                 6.25,41.24 6.91,42.83 8.08,44.00
                                 9.25,45.17 10.84,45.83 12.50,45.83
                                 12.50,45.83 29.17,45.83 29.17,45.83
                                 30.82,45.83 32.41,45.17 33.59,44.00
                                 34.76,42.83 35.42,41.24 35.42,39.58
                                 35.42,39.58 35.42,37.50 35.42,37.50
                                 35.42,37.50 37.50,37.50 37.50,37.50
                                 39.16,37.50 40.75,36.84 41.92,35.67
                                 43.09,34.50 43.75,32.91 43.75,31.25
                                 43.75,31.25 43.75,18.62 43.75,18.62 Z
                               M 31.25,11.27
                               C 31.25,11.27 36.65,16.67 36.65,16.67
                                 36.65,16.67 33.33,16.67 33.33,16.67
                                 32.78,16.67 32.25,16.45 31.86,16.06
                                 31.47,15.67 31.25,15.14 31.25,14.58
                                 31.25,14.58 31.25,11.27 31.25,11.27 Z
                               M 31.25,39.58
                               C 31.25,40.14 31.03,40.67 30.64,41.06
                                 30.25,41.45 29.72,41.67 29.17,41.67
                                 29.17,41.67 12.50,41.67 12.50,41.67
                                 11.95,41.67 11.42,41.45 11.03,41.06
                                 10.64,40.67 10.42,40.14 10.42,39.58
                                 10.42,39.58 10.42,18.75 10.42,18.75
                                 10.42,18.20 10.64,17.67 11.03,17.28
                                 11.42,16.89 11.95,16.67 12.50,16.67
                                 12.50,16.67 14.58,16.67 14.58,16.67
                                 14.58,16.67 14.58,31.25 14.58,31.25
                                 14.58,32.91 15.24,34.50 16.41,35.67
                                 17.59,36.84 19.18,37.50 20.83,37.50
                                 20.83,37.50 31.25,37.50 31.25,37.50
                                 31.25,37.50 31.25,39.58 31.25,39.58 Z
                               M 39.58,31.25
                               C 39.58,31.80 39.36,32.33 38.97,32.72
                                 38.58,33.11 38.05,33.33 37.50,33.33
                                 37.50,33.33 20.83,33.33 20.83,33.33
                                 20.28,33.33 19.75,33.11 19.36,32.72
                                 18.97,32.33 18.75,31.80 18.75,31.25
                                 18.75,31.25 18.75,10.42 18.75,10.42
                                 18.75,9.86 18.97,9.33 19.36,8.94
                                 19.75,8.55 20.28,8.33 20.83,8.33
                                 20.83,8.33 27.08,8.33 27.08,8.33
                                 27.08,8.33 27.08,14.58 27.08,14.58
                                 27.08,16.24 27.74,17.83 28.91,19.00
                                 30.09,20.17 31.68,20.83 33.33,20.83
                                 33.33,20.83 39.58,20.83 39.58,20.83
                                 39.58,20.83 39.58,31.25 39.58,31.25 Z"/>
                                </svg>

                            </a>
                        </span>
                        </div>
                        {/*<div className={classes.promocode}>*/}
                        {/*    По промокоду: <span>LackJet500</span>*/}
                        {/*    <a onClick={() => navigator.clipboard.writeText('LackJet500')} className={classes.link}><img*/}
                        {/*        src={uil}/></a>*/}
                        {/*</div>*/}
                    </div>
                    <div className={classes.right}>
                        <Button
                            text={"ИГРАТЬ В LUCKY JET"}
                            angle={'-0.06'}
                        />
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Footer;