import React from 'react';
import classes from './Copyright.module.sass';

const Copyright = () => {
    return (
        <section className={classes.copyright}>
            <div className={classes.container}>
                <div className={classes.text}>
                    1luckyjet.com является информационным сайтом. Он не предоставляет никакой информации в незаконных целях. Вам необходимо нести ответственность за то, чтобы вам было разрешено играть в азартные игры в соответствии с законами вашей юрисдикции (возраст для азартных игр, правовой статус и т.д.). Посещая данный веб-сайт, вы соглашаетесь с Положениями и Условиями и Политикой конфиденциальности на сайте. 18+
                </div>
            </div>
        </section>
    );
};

export default Copyright;