window.addEventListener('load', () => {
    const menuItems = document.querySelectorAll('.parent');
    menuItems.forEach((item) => {
        // console.log(item);
        item.addEventListener('mouseenter', (e) => {
            const subMenu = item.parentNode.querySelector('.childMenu');
            if (subMenu) subMenu.classList.add('show');
        });
        item.addEventListener('mouseleave', (e) => {
            const subMenu = item.parentNode.querySelector('.childMenu');
            if (subMenu) subMenu.classList.remove('show');
        });
    });
    const answerHead = document.querySelectorAll('.answerHead');
    answerHead.forEach(item => {
       item.addEventListener('click', (e) => {
          e.target.classList.toggle('active');
          let nextElement= e.target.nextElementSibling;
          if (nextElement) nextElement.classList.toggle('active');
       });
    });
    const burger = document.querySelector('.hamburger-menu');
    burger.addEventListener('click', () => {

        burger.querySelector('.bar').classList.toggle('animate');
        let topmenu = document.querySelector('#topmenu');
        topmenu.classList.toggle('show');
        if (topmenu.classList.contains('show')) {
            topmenu.setAttribute('style', 'display: block;');
            let style = topmenu.getAttribute('style');
            style += ' opacity: 1;';
            topmenu.setAttribute('style', style);
        } else{
            topmenu.setAttribute('style', 'display: none;');
            let style = topmenu.getAttribute('style');
            style += ' opacity: 0;';
            topmenu.setAttribute('style', style);
        }
    });
});
