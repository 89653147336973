import React from 'react';
import clasnames from "./Mech.module.sass";

const Mech = () => {
    return (
        <section className={clasnames.mech}>
            <div className={clasnames.container}>
                <h2 className={clasnames.title}>Механика игры Lucky Jet</h2>
                <div className={clasnames.steps}>
                    <div className={clasnames.step}>
                        <div className={clasnames.step_title}>Начало раунда</div>
                        <div className={clasnames.step_text}>Игра начинается с запуска виртуального пилота Lucky в
                            космическом корабле. Игроки делают ставки на то, сколько времени продержится полет, полагаясь на
                            интуицию и анализируя предыдущие матчи.
                        </div>
                    </div>
                    <div className={clasnames.step}>
                        <div className={clasnames.step_title}>Ставки</div>
                        <div className={clasnames.step_text}>Игра начинается с запуска виртуального пилота Lucky в
                            Игроки могут делать ставки до начала полета или в самом начале, пока пилот набирает высоту.
                            Размер ставки определяется игроком и может быть изменен между раундами.
                        </div>
                    </div>
                    <div className={clasnames.step}>
                        <div className={clasnames.step_title}>Множитель</div>
                        <div className={clasnames.step_text}>Во время полета пилот набирает высоту, и множитель выигрыша
                            постепенно увеличивается. Множитель отображается на экране в реальном времени и показывает, во
                            сколько раз увеличивается ставка игрока.
                        </div>
                    </div>
                    <div className={clasnames.step}>
                        <div className={clasnames.step_title}>Выйгрыш</div>
                        <div className={clasnames.step_text}>Величина выигрыша определяется текущим множителем в момент
                            вывода ставки. Если игрок не успел вывести ставку до завершения полета, ставка сгорает.
                        </div>
                    </div>
                    <div className={clasnames.step}>
                        <div className={clasnames.step_title}>Вывод ставки</div>
                        <div className={clasnames.step_text}>Игрок должен вывести свою ставку до того, как пилот покинет
                            пределы экрана (улетит). Ставка может быть выведена в любой момент, пока пилот в полете.
                        </div>
                    </div>
                    <div className={clasnames.step}>
                        <div className={clasnames.step_title}>Конец раунда</div>
                        <div className={clasnames.step_text}>Раунд заканчивается, когда пилот исчезает с экрана, и все ставки, не выведенные до этого момента, теряются. Начинается подготовка к новому раунду, и игроки могут делать новые ставки.
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Mech;