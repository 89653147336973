import Logo from "../Logo/Logo";
import TopMenu from "../TopMenu/TopMenu";
import './Header.module.sass'
import classes from './Header.module.sass'
import burger from '../../assets/burger.svg';
import playImg from "../../assets/play.jpg";
import React from "react";

const Header = () => {
    return (
        <header className={classes.header}>
            <div className={classes.container}>
                <Logo/>
                <TopMenu/>
                <div className="hamburger-menu">
                    <div className="bar"></div>
                </div>
                {/*<div className={classes.burger}>*/}
                {/*    /!*<a id="burger" href="#">*!/*/}
                {/*    /!*    <img src={burger} alt="play"/>*!/*/}
                {/*    /!*</a>*!/*/}

                {/*</div>*/}
            </div>
        </header>
    );
};

export default Header;