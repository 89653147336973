import './App.css';
import Header from "./components/Header/Header";
import Banner from "./components/Banner/Banner";
import './assets/script.js'
import Play from "./components/Play/Play";
import Begin from "./components/Begin/Begin";
import Mech from "./components/Mech/Mech";
import Review from "./components/Review/Review";
import Adv from "./components/Adv/Adv";
import Answer from "./components/Answer/Answer";
import Reviews from "./components/Reviews/Reviews";
import Footer from "./components/Footer/Footer";
import Copyright from "./components/Copyright/Copyright";
import {Helmet} from "react-helmet";


function App() {
  return (
      <div className="App">
          <Helmet>
              <title>Лаки Джет – официальный сайт игры Lucky Jet от 1win</title>

              {/* Метатеги */}
              <meta name="description" content="Официальный сайт игры Lucky Jet от 1вин! Получите промокод 500% на первый дипозит и наслаждайтесь бонусами, чтобы увеличить ваши шансы на успех в Лаки Джет." />
              <meta property="og:title" content="Лаки Джет – официальный сайт игры Lucky Jet от 1win" />

              {/* Другие элементы head */}
              <link rel="canonical" href="https://https://lacky-jet.ru/" />
          </Helmet>
          <Header/>
          <Banner/>
          <Play/>
          <Begin/>
          <Mech/>
          <Review/>
          <Adv/>
          <Answer/>
          <Reviews/>
          <Footer/>
          <Copyright/>
      </div>
  );
}

export default App;
